import React, {useContext, useState} from "react";
import {Box, Button, Card, Container,  makeStyles, TextField, Typography} from "@vtd/ui";
import AuthContext from "contexts/auth";
import {PasswordField} from "../../components/password-field";

const useStyles = makeStyles(() => ({
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // backgroundColor: "#f7f7f7"
  },
  card: {
    width: "360px",
    marginTop: "20vh",
    padding: "40px",
    boxShadow: "0 2.4px 7.2px rgb(0 0 0 / 18%), 0 12.8px 28.8px rgb(0 0 0 / 22%)"
  },
  title: {
    marginTop: "32px"
  },
  input: {
    "backgroundColor": "#fafafa",
    "marginTop": "24px",
    "& input": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px"
    }
  }
}));
export default function AuthPage() {
  const [form, setForm] = useState({name: "", password: ""});
  const [error, setError] = useState("");
  const classes = useStyles();

  const { onLogin } = useContext(AuthContext);

  const handleFieldChange = (e: any) => {
    const { name, value } = e.target;
    setForm({...form, [name]: value});
    error && setError("");
  }

  const onSend = async (e: any) => {
    e.preventDefault();
    if (!form.name || !form.password) {
      setError("Необходимо указать логин и пароль");
    } else {
      try {
        await onLogin(form)
      } catch (e: any) {
        if (e?.response?.data?.message) {
          setError(e?.response?.data?.message);
        } else {
          setError("");
        }
        throw e;
      }
    }
  }

  return (
    <Container
      component="div"
      maxWidth="xl"
      className={classes.container}
    >
      <Box className={classes.title}>
        <Typography variant="h4">
          Интеллектуальная система сопровождения технического диагностирования трубопроводов
        </Typography>
      </Box>
      <Card className={classes.card}>
        <Typography variant="h5" align="center" gutterBottom>
          Авторизация
        </Typography>
        <form noValidate onSubmit={onSend}>
          <TextField
            className={classes.input}
            size="small"
            inputId="user-name"
            label="Имя пользователя"
            name="name"
            value={form.name}
            error={!!error}
            onChange={handleFieldChange}
            onBlur={handleFieldChange}
            required
            autoFocus
            fullWidth
          />
          <PasswordField
              label="Пароль"
              name="password"
              classNames={classes.input}
              value={form.password}
              error={!!error}
              onChange={handleFieldChange}
              required
          />
          {!!error &&
              <Typography color="error" variant="subtitle2" style={{margin: "8px"}}>
                {error}
              </Typography>}
          <Box display="flex" justifyContent="flex-end">
            <Button
              type="submit"
              style={{width: "100%", marginTop: 32}}
              color="primary"
              variant="contained"
              label="Войти"
              disabled={!!error}
            />
          </Box>
        </form>
      </Card>
    </Container>
  )
}
