import { ListItem, ListItemIcon, ListItemText, makeStyles } from "@vtd/ui";
import * as React from "react";
import { useFormManager } from "system";
import {useContext} from "react";
import AuthContext from "../../../contexts/auth";

const useStyles = makeStyles(() => ({
  listItemText: {
    paddingTop: 2, // fix icon center
  },
}));

export interface IDrawerMenuItemProps {
  index: number;
  icon: React.ReactElement;
  label: string;
  page: string;
  permission?: {prefix: string, keys: string[]},
  isSubItem: boolean;
  onClick?: () => void;
}

function DrawerMenuItem({ index, page, label, permission, icon, isSubItem, onClick }: IDrawerMenuItemProps) {
  const classes = useStyles();
  const { openForm } = useFormManager();

  const {checkUserPermission} = useContext(AuthContext);

  const isPermission = permission ? checkUserPermission(permission.prefix, permission.keys) : true ;

  return (
    <li id={`main-menu-item-${page}`}>
      <ListItem
        button
        disabled={!isPermission}
        onClick={(e) => {
            if (isPermission) {
                if (onClick instanceof Function) {
                    onClick.call(null);
                }
                if (page) {
                    openForm(page, {}, true);
                }
            } else {
                e.preventDefault();
                e.stopPropagation();
            }
        }}
      >
        <ListItemIcon style={isSubItem ? {minWidth: "40px", marginLeft: "32px"} : {}}>
          {icon}
        </ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          primary={label}
        />
      </ListItem>
    </li>
  );
}

export default DrawerMenuItem;
