import { createContext } from "react";
import {IUser} from "../api/auth";

interface IAuthContext {
  user: IUser | null
  onCheckAuth: () => any
  onLogin: (body: any) => any
  onLogout: () => void
  checkUserPermission: (prefix: string, keys: string[], variant?: "some" | "every") => boolean
}

export const authContextDefaults: IAuthContext = {
  user: null,
  onCheckAuth: () => null,
  onLogin: () => null,
  onLogout: () => null,
  checkUserPermission: () => false
};

export default createContext<IAuthContext>(authContextDefaults);
