import {SnackbarKey, useSnackbar} from "notistack";
import React, {useContext} from "react";

import Close from "@material-ui/icons/Close";

import {Box, formatDate, IconButton, Link} from "@vtd/ui";

import { registerHttpClient } from "api/api";
import {getTokenString, REFRESH_TOKEN_URL} from "api/auth";
import fileSaver from "file-saver";
import HttpClient from "../http-client";
import {snackRef} from "../snackbar-provider/provider";

import ApiContext from "contexts/api";
import AuthContext from "contexts/auth";

interface IApiProvider {
  children?: React.ReactNode;
}

export function ApiProvider({ children }: IApiProvider) {
  const { enqueueSnackbar } = useSnackbar();
  const { onCheckAuth } = useContext(AuthContext);

  const getTraceId = (headers: any) => {
    return headers.traceid || null
  }

  React.useEffect(() => {
    HttpClient.instance.interceptors.request.use((config: any) => {
      config.headers.Authorization = getTokenString();
      return config;
    }, (error: any) => {
      enqueueSnackbar(error.message, { variant: "error" });
      return error;
    });
    HttpClient.instance.interceptors.response.use((config: any) => {
      console.log("traceId:", `${getTraceId(config.headers)} (${config.config?.url || ""})`);
      return config;
    }, async (error: any) => {
      if (error.response) {
        // не заходим сюда если была ошибка на запрос рефреш-токена
        if (error.config.url !== REFRESH_TOKEN_URL) {
          const originalRequest = error.config;
          console.error("OMG error! (id:", getTraceId(error.response.headers), ")");

          switch (error.response.status) {
            case 400:
            case 409: {
              if (error.response.data?.message) {
                const createFile = () => {
                  const value = error.response.data;
                  const text = {
                    status: error.response.status,
                    path: value.path,
                    statusText: value.status,
                    timestamp: value.timestamp,
                    details: value.details,
                    comment: value.comment,
                    stack_trace: value.stack_trace
                  };
                  const fileName = `Error-${formatDate(new Date(), "dd.MM.yyyy HH:mm:ss")}.txt`;

                  const file = new File([JSON.stringify(text, null, 2)], fileName, {type: "text/plain;charset=utf-8"});
                  fileSaver.saveAs(file);
                }

                const onClickDismiss = (key: SnackbarKey) => () => {
                  snackRef.current.closeSnackbar(key);
                };

                const action = (key: SnackbarKey, url: any = "") => (
                    <Box>
                      <Link
                          color="inherit"
                          underline="always"
                          style={{margin: 12}}
                          onClick={createFile}
                      >
                        'Сохранить ответ'
                      </Link>
                      <IconButton onClick={onClickDismiss(key)}>
                        <Close/>
                      </IconButton>
                    </Box>
                );

                enqueueSnackbar(
                    <div
                        dangerouslySetInnerHTML={{__html: `${error.response.data.message}`}}/>,
                    {variant: "error", action}
                );
              } else if (error.config.responseType === "blob") {
                  enqueueSnackbar("Ошибка при скачивании файла", {variant: "error"});
              }
              break;
            }

            case 401: {
              if (!error.config._isRetry) {
                originalRequest._isRetry = true;
                await onCheckAuth();
                return HttpClient.instance.request(originalRequest);
              }
              break;
            }

            case 403: {
              enqueueSnackbar("Нет доступа для данной операции", {variant: "error"});
              break;
            }

            case 404: {
              if (error.response.data?.message) {
                enqueueSnackbar(<div
                    dangerouslySetInnerHTML={{__html: `${error.response.data.message}`}}/>, {variant: "error"});
              } else {
                enqueueSnackbar("Информация не найдена", {variant: "error"});
              }
              break;
            }

            case 500:
            case 502:
            case 504: {
              enqueueSnackbar("Сервер временно недоступен", {variant: "error"});
              break;
            }

            default: {
              if (error.response.data?.message) {
                enqueueSnackbar(<div
                    dangerouslySetInnerHTML={{__html: `${error.response.data.message}`}}/>, {variant: "error"});
              } else {
                enqueueSnackbar(`Неизвестная ошибка. ${error.response.status || ""}`, {variant: "error"});
              }
            }
          }
        }
      }
      throw error;
    });

    registerHttpClient(HttpClient);
    // eslint-disable-next-line
  }, []);

  return (
    <ApiContext.Provider value={{}}>
      {children}
    </ApiContext.Provider>
  );
}

export default ApiProvider;
