import axios from "axios";
import jwtDecode from "jwt-decode";

import api from "./api";
import { IPagingSearch } from "interfaces";

export const TOKEN_KEY = "vtd_token";
export const REFRESH_TOKEN_KEY = "vtd_refresh_token";
export const REFRESH_TOKEN_URL = "/api/dc/auth/refresh_token";

export interface IUserRoles {
  grantedAuthority: string
}

interface IAuthTokenData {
  userId: number
  username: string
  shortName: string
  orgId: number
  orgName: string
  roles: any
  exp: number
}

export interface IUser {
  userId: number
  username: string
  shortName: string
  orgId: number
  orgName: string
  userRole: string
  roles: IUserRoles
}

export const setToken = (token: string, refreshToken: string) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
}

export const clearToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
}

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY) as "string";
}

export const getTokenString = () => {
  const token =  localStorage.getItem(TOKEN_KEY) || "";
  return `Bearer ${token}`;
}

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_KEY) as "string";
}

// данные пользователя из токена
const getUserData = (token: string): IUser => {
  const { userId, username, shortName, orgId, orgName, roles} = jwtDecode<IAuthTokenData>(token);

  return {
    userId,
    username,
    shortName,
    orgId,
    orgName,
    userRole: roles.grantedAuthority || "",
    roles
  };
}

export async function signIn(username: string, password: string): Promise<IUser> {
  const res = await api.post("/dc/auth/sign_in", {username, password});

  const {accessToken, refreshToken} = res;
  setToken(accessToken, refreshToken);

  return getUserData(accessToken);
}

export async function signOut() {
  const accessToken = getToken();
  api.post("/dc/auth/sign_out", {accessToken}).then(clearToken);
}

export async function checkAuth() {
  try {
    const oldRefreshToken = getRefreshToken() || "";

    const res = await axios.post(REFRESH_TOKEN_URL, {refreshToken: oldRefreshToken});

    const {accessToken, refreshToken} = res.data;
    setToken(accessToken, refreshToken);

    return getUserData(accessToken);
  } catch (e) {
    // console.error("Refresh token error", e)
    // return null;
    throw e;
  }
}

interface IAuthServiceDtoMeta {
  createdDate: string
  createdBy: number
  updatedDate: string
  updatedBy: number
}

// users
export interface IUserDto extends IAuthServiceDtoMeta {
  id: number
  username: string
  roleId: number
  surname: string
  name: string
  patronymic: string
  orgId: number
  orgPosition: string
  deletedDate: string | null
  admin: boolean
}

export async function getUsers(page = 0, size = 10): Promise<IPagingSearch<IUserDto>> {
  // return await api.get(`/auth/users/search?page=${page}&size=${size}`);
  return await api.get(`/auth/users/search?page=${page}`);
}

export async function createUser(data: IUserDto): Promise<IUserDto> {
  return await api.post(`/auth/users`, data);
}

export async function updateUser(id: number, data: Partial<IUserDto>): Promise<IUserDto> {
  return await api.patch(`/auth/users/${id}`, data);
}

// изменить пароль юзера
export async function updateUserPassword(id: number, password: string) {
  await api.put(`/auth/users/${id}/password`, {password});
  return true;
}

// проверить наличие пользователя по username
export async function checkUserExists(username: string): Promise<boolean> {
  return await api.get(`/auth/users?username=${username}`);
}

// пометить юзера на удаление
export async function deleteUser(id: number) {
  await api.delete(`/auth/users/${id}`);
  return true;
}

// пометить юзера на удаление
export async function recoverUser(id: number) {
  await api.post(`/auth/users/${id}/recover`, id);
  return true;
}

//
// организации для юзеров
export interface IUserOrg extends IAuthServiceDtoMeta {
  id: number
  name: string
}

export async function getUserOrgs(page = 0, size?: number): Promise<IPagingSearch<IUserOrg>> {
  const getSize = () => `&size=${size}`
  return await api.get(`/auth/orgs/search?page=${page}${size ? getSize() : ""}`);
}

//
// роли
export interface IRole extends IAuthServiceDtoMeta {
  id: number
  name: string
  shortDescription: string
  groupJson: string
}

export async function getRoles(page = 0, size?: number): Promise<IPagingSearch<IRole>> {
  const getSize = () => `&size=${size}`
  return await api.get(`/auth/roles/search?page=${page}${size ? getSize() : ""}`);
}

export async function createRole(data: IRole): Promise<IRole> {
  return await api.post(`/auth/roles`, data);
}

export async function updateRole(id: number, data: Partial<IRole>): Promise<IRole> {
  return await api.patch(`/auth/roles/${id}`, data);
}

// удалить роль
export async function deleteRole(id: number) {
  await api.delete(`/auth/roles/${id}`);
  return true;
}
