import React, {useCallback, useEffect, useState} from "react";
import {ThemeProvider} from "@vtd/ui";
import { IForm } from ".";
import {useLocalStorageState} from "../utils/hooks";
import ApiProvider from "./api-provider/provider";
import AuthProvider from "./authorization";
import { FormProvider } from "./form-manager/form-provider";
import { SnackbarProvider } from "./snackbar-provider/provider";
import { getAppTheme } from "../theme";

export const SystemContext = React.createContext<any>({});

interface ISystemProviderProps {
  children?: React.ReactNode
  // theme: Theme
  forms: IForm[]
  main: string
}

export const SystemProvider = ({ forms, main, children }: ISystemProviderProps) => {
  // масштаб: true - больше, false - меньше
  const [appScale, setAppScale] = useState(true);

  // todo переделать это на работу с беком
  // людям с тонкой душевной организацией не смотреть
  const [progressData, setProgressData] = useLocalStorageState("ttcs-form-a-calc-timer", []);

  const deleteLCDataByUid = (uid_gto: number) => {
    const filteredData = progressData.filter((item: any) => item.uid !== uid_gto);
    setProgressData(filteredData);
  }

  const startFakeProgress = async (gtoList: any) => {
    const delay = 20;
    for (const gto of gtoList) {
      deleteLCDataByUid(gto.uid);
      // const response = await MgCatalogApi.getAdkDdkDefects({gto_uid: gto.uid});
      computeProgress(gto, delay);
    }
  };

  const computeProgress = (gto: any, delay: number) => {
    let current = 0;
    // рандомный макс, меньше 100
    const lowMax = 94;
    const topMax = 99;
    const maxValue = Math.floor(lowMax + Math.random() * (topMax + 1 - lowMax));

    // шаг зависит от количества кц
    const step = gto.dep_count ? (100 * delay / 60) / (gto.dep_count * 2) : 100;
    const timer = setInterval(() => intervalCallback(), delay * 1000);

    function intervalCallback() {
      if (current >= maxValue) {
        // @ts-ignore
        clearInterval(timer);
      } else {
        const filteredData = progressData.filter((item: any) => item.uid !== gto.uid);
        const newValue = {uid: gto.uid, progress: current > maxValue ? maxValue : current};
        setProgressData(() => [...filteredData, newValue]);
      }
      current += step >= 1 ? Math.floor(step) : 1;
    }
    intervalCallback();
  };

  const updateScale = useCallback(() => {
    const height = window.innerHeight || 1000;
    setAppScale(height > 860);
  }, [setAppScale]);

  useEffect(() => {
    updateScale();
  }, [updateScale]);

  return (
    <SystemContext.Provider value={{
      startFakeProgress,
      appScale,
      setAppScale
    }}>
      <ThemeProvider theme={getAppTheme(appScale)}>
        <SnackbarProvider>
          <AuthProvider>
            <ApiProvider>
              <FormProvider forms={forms} main={main}>
                {children}
              </FormProvider>
            </ApiProvider>
          </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </SystemContext.Provider>
  );
};
