import * as React from "react";
import {IForm} from "system";

export default [
  {
    name: "home",
    component: React.lazy(() => import("./home")),
  },
  {
    name: "auth",
    component: React.lazy(() => import("./auth")),
  },
  {
    name: "ttcs/dashboard",
    component: React.lazy(() => import("./ttcs/dashboard")),
    permission: {prefix: "ttcs_common", keys: ["gto_read"]},
  },
  {
    name: "ttcs/gto-add",
    component: React.lazy(() => import("./ttcs/gto-edit")),
    permission: {prefix: "ttcs_common", keys: ["gto_create"]},
  },
  {
    name: "ttcs/gto-edit",
    component: React.lazy(() => import("./ttcs/gto-edit")),
    permission: {prefix: "ttcs_common", keys: ["gto_update"]},
  },
  {
    name: "ttcs/tt-list",
    component: React.lazy(() => import("./ttcs/pipeline-group")),
    permission: {prefix: "ttcs_common", keys: ["ttgroup_read"]},
  },
  {
    name: "ttcs/tt-edit",
    component: React.lazy(() => import("./ttcs/tt-edit")),
    permission: {prefix: "ttcs_common", keys: ["ttgroup_update"]},
  },
  {
    name: "ttcs/retrain",
    component: React.lazy(() => import("./ttcs/retrain")),
    permission: {prefix: "routes", keys: ["ttcs_retrain"]},
  },
  {
    name: "ttcs/dicts",
    component: React.lazy(() => import("./ttcs/ttcs-dicts")),
    permission: {prefix: "routes", keys: ["ttcs_dicts"]},
  },
  {
    name: "ttcs/form-a",
    component: React.lazy(() => import("./ttcs/form-a-calc")),
    permission: {prefix: "routes", keys: ["ttcs_calc"]},
  },
  {
    name: "ttcs/analytics",
    component: React.lazy(() => import("./ttcs/analytics")),
    permission: {prefix: "routes", keys: ["ttcs_stats"]},
  },
  {
    name: "ttcs/routes/list",
    component: React.lazy(() => import("./routes/list")),
    permission: {prefix: "ttcs_route", keys: ["routelist_read"]},
  },
  {
    name: "ttcs/routes/edit",
    component: React.lazy(() => import("./routes/edit")),
    permission: {prefix: "ttcs_route", keys: ["route_update"]},
  },
  {
    name: "ttcs/routes/add",
    component: React.lazy(() => import("./routes/edit")),
    permission: {prefix: "ttcs_route", keys: ["route_create"]},
  },
  {
    name: "ttcs/routes/summary",
    component: React.lazy(() => import("./routes/summary")),
    permission: {prefix: "ttcs_route", keys: ["route_read"]},
  },
  {
    name: "ttcs/routes/table",
    component: React.lazy(() => import("./routes/table")),
    permission: {prefix: "ttcs_route", keys: ["result_read"]},
  },
  {
    name: "ttcs/routes/tube-position",
    component: React.lazy(() => import("./routes/tube-position")),
    permission: {prefix: "ttcs_route", keys: ["position_read"]},
  },
  {
    name: "ttcs/routes/tube-registry",
    component: React.lazy(() => import("./routes/tube-registry")),
    permission: {prefix: "ttcs_route", keys: ["treg_update"]},
  },
  {
    name: "ttcs/routes/3d-constructor",
    component: React.lazy(() => import("./routes/3d-constructor")),
    permission: {prefix: "ttcs_route", keys: ["union_update"]},
  },
  {
    name: "ttcs/routes/union_summary",
    component: React.lazy(() => import("./routes/summary/union_summary")),
    permission: {prefix: "ttcs_route", keys: ["union_read"]},
  },
  {
    name: "ttcs/routes/union_table",
    component: React.lazy(() => import("./routes/table/union_table")),
    permission: {prefix: "ttcs_route", keys: ["resultunion_read"]},
  },
  {
    name: "mg/dashboard",
    component: React.lazy(() => import("./mg/dashboard")),
    permission: {prefix: "mg_common", keys: ["mgpart_read"]},
  },
  {
    name: "mg/part-add",
    component: React.lazy(() => import("./mg/part-edit")),
    permission: {prefix: "mg_common", keys: ["mgpart_create"]},
  },
  {
    name: "mg/part-edit",
    component: React.lazy(() => import("./mg/part-edit")),
    permission: {prefix: "mg_common", keys: ["mgpart_update"]},
  },
  {
    name: "mg/models",
    component: React.lazy(() => import("./mg/mg-retrain")),
    permission: {prefix: "routes", keys: ["mg_retrain"]},
  },
  {
    name: "mg/dicts",
    component: React.lazy(() => import("./mg/mg-dicts")),
    permission: {prefix: "routes", keys: ["mg_dicts"]},
  },
  {
    name: "mg/routes/survey-list",
    component: React.lazy(() => import("./mg-routes/survey-list")),
    permission: {prefix: "mg_common", keys: ["mgsurvey_read"]},
  },
  {
    name: "mg/routes/add",
    component: React.lazy(() => import("./mg-routes/edit")),
    permission: {prefix: "mg_common", keys: ["mgsurvey_create"]},
  },
  {
    name: "mg/routes/edit",
    component: React.lazy(() => import("./mg-routes/edit")),
    permission: {prefix: "mg_survey", keys: ["mgsurvey_edit", "mgsurveycommon_edit", "mgsurveyvip_edit", "mgsurveyvtd_edit", "mgsurveyddk_edit", "mgsurveydefects_edit"], variant: "some"},
  },
  {
    name: "about/info",
    component: React.lazy(() => import("./about/info")),
  },
  {
    name: "profile/info",
    component: React.lazy(() => import("./profile/info")),
  },
  {
    name: "profile/admin",
    component: React.lazy(() => import("./profile/admin")),
    permission: {prefix: "profile", keys: ["users_crud", "roles_crud"], variant: "some"},
  },
  {
    name: "reports/list",
    component: React.lazy(() => import("./reports/list")),
    permission: {prefix: "routes", keys: ["stimul_reports"]},
    },
  {
    name: "odp-adk/dashboard",
    component: React.lazy(() => import("./odp-adk/dashboard")),
    permission: {prefix: "routes", keys: ["odp_adk"]},
  },
  {
    name: "odp-adk/adk-edit",
    component: React.lazy(() => import("./odp-adk/adk-edit")),
    permission: {prefix: "routes", keys: ["odp_adk"]},
  },
  {
    name: "krn/dashboard",
    component: React.lazy(() => import("./krn/dashboard")),
  },
  {
    name: "krn/part-add",
    component: React.lazy(() => import("./krn/part-edit")),
  },
  {
    name: "krn/part-edit",
    component: React.lazy(() => import("./krn/part-edit")),
  },
  {
    name: "krn/survey/survey-list",
    component: React.lazy(() => import("./krn/survey-list")),
  },
  {
    name: "krn/survey/survey-add",
    component: React.lazy(() => import("./krn/krn-survey")),
  },
  {
    name: "krn/survey/survey-edit",
    component: React.lazy(() => import("./krn/krn-survey")),
  },
  {
    name: "404",
    component: React.lazy(() => import("./404/not-found")),
  },
  {
    name: "403",
    component: React.lazy(() => import("./403/forbidden")),
  }
] as IForm[];
