import * as React from "react";
import { IForm, IFormParams } from "../index";

export interface IPageRouteProps {
  form: IForm;
  params?: IFormParams;
}

export default function Form({ form, params }: IPageRouteProps) {
  return (
    <>
      <form.component params={params} />
    </>
  );
}
