import Close from "@material-ui/icons/Close";
import {IconButton} from "@vtd/ui";
import React from "react";
import { SnackbarProvider as NotistackProvider, SnackbarKey } from "notistack";

interface ISnackbarProvider {
  children?: React.ReactElement<any, any>;
}

export const snackRef: any = React.createRef();

export function SnackbarProvider({children}: ISnackbarProvider) {

  const onClickDismiss = (key: SnackbarKey) => () => {
    snackRef.current.closeSnackbar(key);
  };

  return (
    <NotistackProvider
      maxSnack={5}
      ref={snackRef}
      action={(key) => (
        <IconButton onClick={onClickDismiss(key)}>
          <Close/>
        </IconButton>
      )}
    >
      {children}
    </NotistackProvider>
  );
}
