import {createTheme} from "@vtd/ui";

import "./fonts/iconfont/material-icons.css";
import "./fonts/passwordfont/password-font.css"
import "./fonts/roboto-v20-latin_cyrillic/roboto-v20-latin_cyrillic.css";
import getTypography from "./typography";

export const getAppTheme = (scale: boolean) => createTheme({
  typography: getTypography(scale),
  spacing: scale ? 8 : 4,
  overrides: scale ? {...bigScaleOverrides} : {...smallScaleOverrides}
});

const bigScaleOverrides = {
  MuiButton: {
    root: {
      padding: "6px 16px"
    },
    outlined: {
      padding: "6px 16px"
    },
    text: {
      padding: "6px 8px"
    }
  },
  MuiIconButton: {
    root: {
      padding: "12px"
    }
  },
  MuiTab: {
    root: {
      padding: "10px 12px"
    }
  },
  MuiTextField: {
    root: {
      "& > div.MuiAutocomplete-inputRoot": {
        padding: "9px",
        paddingRight: "56px"
      }
    }
  },

  MuiInputLabel: {
    root: {
      whiteSpace: "nowrap" as "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "80%",
      height: "100%"
    },
    shrink: {
      maxWidth: "109%"
    },
    outlined: {
      transform: "translate(14px, 20px) scale(1)"
    }
  },
  MuiOutlinedInput: {
    input: {
      padding: "18px 14px"
    }
  },
  MuiTableCell: {
    root: {
      padding: "10px 16px"
    },
    head: {
      padding: "10px 16px"
    }
  },
  MuiToolbar: {
    regular: {
      minHeight: 64
    }
  },
  MuiTooltip: {
    tooltip: {
      fontSize: 12,
    }
  },
  MuiCssBaseline: {
    "@global": {
      "a": {
        cursor: "pointer",
      },
      ".waymap": {
        "width": "100%",
        "height": "100%",
        "& .canvas-container": {
          width: "100% !important",
          height: "100% !important",
        },
        "& .layers-map-canvas": {
          // width: '100% !important',
          // height: '100% !important',
          // objectFit: 'cover'
        },
      },
    },
  }
};

const smallScaleOverrides = {
  MuiButton: {
    root: {
      padding: "4px 16px"
    },
    outlined: {
      padding: "4px 16px"
    },
    text: {
      padding: "4px 8px"
    }
  },
  MuiIconButton: {
    root: {
      padding: "6px"
    }
  },
  MuiTab: {
    root: {
      padding: "4px 8px"
    }
  },
  MuiTextField: {
    root: {
      "& > div.MuiAutocomplete-inputRoot": {
        padding: "0 9px",
        paddingRight: "56px"
      }
    }
  },
  MuiInputLabel: {
    root: {
      whiteSpace: "nowrap" as "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "80%",
      height: "100%"
    },
    shrink: {
      maxWidth: "109%"
    },
    outlined: {
      transform: "translate(14px, 12px) scale(1)"
    }
  },
  MuiOutlinedInput: {
    input: {
      padding: "10px 10px"
    }
  },
  MuiTableCell: {
    root: {
      padding: "6px 8px",
    },
    head: {
      padding: "6px 8px 8px",
    }
  },
  MuiToolbar: {
    regular: {
      minHeight: "48px !important"
    }
  },
  MuiTooltip: {
    tooltip: {
      fontSize: 12,
    }
  },
  MuiCssBaseline: {
    "@global": {
      "a": {
        cursor: "pointer",
      },
      ".waymap": {
        "width": "100%",
        "height": "100%",
        "& .canvas-container": {
          width: "100% !important",
          height: "100% !important",
        },
        "& .layers-map-canvas": {
          // width: '100% !important',
          // height: '100% !important',
          // objectFit: 'cover'
        }
      }
    }
  }
};
