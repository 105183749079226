import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";
import {AppBar, Box, IconButton, makeStyles, Theme, Toolbar, Typography} from "@vtd/ui";
import * as React from "react";
import { IThreadItem, useFormManager } from "system";
import AppDrawer from "../app-drawer/app-drawer";

export interface IAppTopBarProps {
  appTitle: string;
  title: string;
  thread: IThreadItem[];
  drawerMenu?: any;
  toolbarContent?: any;
  children?: any;
}

const styles = ({ spacing, zIndex }: Theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
  },
  arrowSpace: {
    width: `calc(28px + ${spacing(3)}px)`
  },
  menuButton: {
    margin: spacing(0, 2, 0, 1),
  },
  arrowButton: {
    marginRight: spacing(2),
  },
  title: {
    flexGrow: 1,
  },
});

const useStyles = makeStyles(styles);

function AppTopBar({ appTitle, title, thread, drawerMenu, children, toolbarContent }: IAppTopBarProps) {
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
  const { closeForm } = useFormManager();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {drawerMenu && (
        <AppDrawer
          appTitle={appTitle}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          {drawerMenu}
        </AppDrawer>
      )}
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            id="menu-button"
            edge="start"
            className={classes.menuButton}
            onClick={() => setDrawerOpen(true)}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon/>
          </IconButton>
          {thread.length > 1 ?
            <IconButton
              id="history-back-button"
              // edge="start"
              className={classes.arrowButton}
              onClick={() => closeForm()}
              color="inherit"
            >
              <ArrowBackIcon/>
            </IconButton>
            : <Box className={classes.arrowSpace}> </Box>}

          <Typography
            variant="h4"
            className={classes.title}
          >
            {title}
          </Typography>

          {/*{thread.length <= 1 && toolbarContent}*/}
          {toolbarContent}
        </Toolbar>
      </AppBar>

      {children}
    </div>
  );
}

export default AppTopBar;
