
interface IRoleKeys {
  title: string
  key: string
  value: number
}

export interface IRoleGroup {
  title: string
  key: string
  items: IRoleKeys[]
}

export const defaultProfile = {
  ttcs_common: 258,
  ttcs_route: 12352,
  mg_common: 136,
  mg_survey: 1
}

export const vtdRoles: IRoleGroup[] = [
  {
    title: "Страница профиля",
    key: "profile",
    items: [
      {
        title: "Добавление и редактирование пользователей",
        key: "users_crud",
        value: 2
      },
      {
        title: "Добавление и редактирование ролей",
        key: "roles_crud",
        value: 1
      }
    ]
  },
  {
    title: "Раздел ТТКС",
    key: "ttcs_common",
    items: [
      {
        title: "Просмотр ГТО",
        key: "gto_read",
        value: 256
      },
      {
        title: "Создание ГТО",
        key: "gto_create",
        value: 128
      },
      {
        title: "Редактирование ГТО",
        key: "gto_update",
        value: 64
      },
      {
        title: "Удаление ГТО",
        key: "gto_delete",
        value: 32
      },
      {
        title: "Экспорт формы Ж",
        key: "z_export",
        value: 16
      },
      {
        title: "Экспорт формы А",
        key: "a_export",
        value: 8
      },
      {
        title: "Редактирование показателей тех. сост. надежности",
        key: "indicators_update",
        value: 4
      },
      {
        title: "Просмотр объектов ТТКС",
        key: "ttgroup_read",
        value: 2
      },
      {
        title: "Редактирование объектов ТТКС",
        key: "ttgroup_update",
        value: 1
      },
    ]
  },
  {
    title: "Маршруты ТТКС",
    key: "ttcs_route",
    items: [
      {
        title: "Просмотр списка маршрутов",
        key: "routelist_read",
        value: 8192
      },
      {
        title: "Просмотр маршрута",
        key: "route_read",
        value: 4096
      },
      {
        title: "Создание маршрута",
        key: "route_create",
        value: 2048
      },
      {
        title: "Редактирование маршрута",
        key: "route_update",
        value: 1024
      },
      {
        title: "Удаление маршрута",
        key: "route_delete",
        value: 512
      },
      {
        title: "Редактирование схемы КЦ",
        key: "schema_update",
        value: 256
      },
      {
        title: "Выгрузка схемы КЦ",
        key: "schema_download",
        value: 128
      },
      {
        title: "Просмотр объединенного маршрута",
        key: "union_read",
        value: 64
      },
      {
        title: "Редактирование объединенного маршрута",
        key: "union_update",
        value: 32
      },
      {
        title: "Результирующая таблица объединенного маршрута",
        key: "resultunion_read",
        value: 16
      },
      {
        title: "Результирующая таблица",
        key: "result_read",
        value: 8
      },
      {
        title: "Пространственное положение",
        key: "position_read",
        value: 4
      },
      {
        title: "Редактирование трубного журнала",
        key: "treg_update",
        value: 2
      },
      {
        title: "Переход к редактированию маршрута из РТ",
        key: "back_route_update",
        value: 1
      }
    ]
  },
  {
    title: "Раздел ЛЧМГ",
    key: "mg_common",
    items: [
      {
        title: "Просмотр участков МГ",
        key: "mgpart_read",
        value: 128
      },
      {
        title: "Создание участков МГ",
        key: "mgpart_create",
        value: 64
      },
      {
        title: "Редактирование участков МГ",
        key: "mgpart_update",
        value: 32
      },
      {
        title: "Удаление участков МГ",
        key: "mgpart_delete",
        value: 16
      },
      {
        title: "Просмотр списка обследований ЛЧМГ",
        key: "mgsurvey_read",
        value: 8
      },
      {
        title: "Создание обследования ЛЧМГ",
        key: "mgsurvey_create",
        value: 4
      },
      // {
      //   title: "Редактирование обследования ЛЧМГ",
      //   key: "mgsurvey_update",
      //   value: 2
      // },
      {
        title: "Удаление обследования ЛЧМГ",
        key: "mgsurvey_delete",
        value: 1
      },
    ]
  },
  {
    title: "Редактирование обследования ЛЧМГ",
    key: "mg_survey",
    items: [
      {
        title: "Переход во вкладки обследования ЛЧМГ",
        key: "mgsurvey_edit",
        value: 32
      },
      {
        title: "Общие сведения обследования ЛЧМГ",
        key: "mgsurveycommon_edit",
        value: 16
      },
      {
        title: "Первичные данные обследования ЛЧМГ",
        key: "mgsurveyvip_edit",
        value: 8
      },
      {
        title: "Отчеты по ВТД обследования ЛЧМГ",
        key: "mgsurveyvtd_edit",
        value: 4
      },
      {
        title: "Данные ДДК обследования ЛЧМГ",
        key: "mgsurveyddk_edit",
        value: 2
      },
      {
        title: "Дефекты обследования ЛЧМГ",
        key: "mgsurveydefects_edit",
        value: 1
      },
    ]
  },
  {
    title: "Отображение разделов в меню",
    key: "routes",
    items: [
      {
        title: "Раздел 'Дообучение ТТКС'",
        key: "ttcs_retrain",
        value: 128
      },
      {
        title: "Раздел 'Справочники ТТКС'",
        key: "ttcs_dicts",
        value: 64
      },
      {
        title: "Раздел 'Дообучение ЛЧМГ'",
        key: "mg_retrain",
        value: 32
      },
      {
        title: "Раздел 'Справочники ЛЧМГ'",
        key: "mg_dicts",
        value: 16
      },
      {
        title: "Раздел 'ОДП АДК'",
        key: "odp_adk",
        value: 8
      },
      {
        title: "Раздел 'Отчеты (Stimul)'",
        key: "stimul_reports",
        value: 4
      },
      {
        title: "Раздел 'Аналитика и статистика ТТКС'",
        key: "ttcs_stats",
        value: 2
      },
      {
        title: "Раздел 'Расчет показателей ТТКС'",
        key: "ttcs_calc",
        value: 1
      },
    ]
  }
]
