import React, {useContext} from "react";

import CopyrightIcon from "@material-ui/icons/Copyright";
import DescriptionIcon from "@material-ui/icons/Description";
import ExtensionIcon from "@material-ui/icons/Extension";
import RemoveIcon from "@material-ui/icons/Remove";

import { CssBaseline, Theme, withStyles } from "@vtd/ui";

import { AppTopBar, DrawerMenu, IUserMenuItem, UserMenu } from "components/dashboard";
import AuthContext from "contexts/auth";
import { FormManager, useForm } from "system";

const drawerMenuItems = [{
  page: "ttcs/dashboard",
  permission: {prefix: "ttcs_common", keys: ["gto_read"]},
  label: "ДК ТТ КС",
  icon: <ExtensionIcon />,
  isSubItem: false,
}, {
  page: "ttcs/retrain",
  permission: {prefix: "routes", keys: ["ttcs_retrain"]},
  label: "Дообучение ТТ КС",
  icon: <RemoveIcon />,
  isSubItem: true,
}, {
  page: "ttcs/dicts",
  permission: {prefix: "routes", keys: ["ttcs_dicts"]},
  label: "Справочники ТТ КС",
  icon: <RemoveIcon />,
  isSubItem: true,
}, {
  page: "ttcs/form-a",
  permission: {prefix: "routes", keys: ["ttcs_calc"]},
  label: "Расчет показателей",
  icon: <RemoveIcon />,
  isSubItem: true,
}, {
  page: "ttcs/analytics",
  permission: {prefix: "routes", keys: ["ttcs_stats"]},
  label: "Аналитика и Статистика",
  icon: <RemoveIcon />,
  isSubItem: true,
}, {
  page: "mg/dashboard",
  permission: {prefix: "mg_common", keys: ["mgpart_read"]},
  label: "ДК ЛЧ МГ",
  icon: <ExtensionIcon />,
  isSubItem: false,
}, {
  page: "mg/models",
  permission: {prefix: "routes", keys: ["mg_retrain"]},
  label: "Дообучение ЛЧ МГ",
  icon: <RemoveIcon />,
  isSubItem: true,
}, {
  page: "mg/dicts",
  permission: {prefix: "routes", keys: ["mg_dicts"]},
  label: "Справочники ЛЧ МГ",
  icon: <RemoveIcon />,
  isSubItem: true,
}, {
  page: "krn/dashboard",
  // permission: {prefix: "routes", keys: ["mg_dicts"]},
  label: "Классификация КРН",
  icon: <ExtensionIcon />,
  isSubItem: false,
}, {
  page: "odp-adk/dashboard",
  permission: {prefix: "routes", keys: ["odp_adk"]},
  label: "ОДП АДК",
  icon: <ExtensionIcon />,
  isSubItem: false,
}, {
  page: "reports/list",
  permission: {prefix: "routes", keys: ["stimul_reports"]},
  label: "Отчеты",
  icon:  <DescriptionIcon />,
  isSubItem: false,
}, {
  page: "about/info",
  label: "О программе",
  icon:  <CopyrightIcon />,
  isSubItem: false,
}];

// const userMenuItems: IUserMenuItem[] = [{
//   label: "Профиль",
//   page: "profile/info",
// }, {
//   label: "Выход",
//   onClick: () => {
//     logout();
//   },
// }];

interface IDashboardProps {
  classes?: any;
}

function App({ classes }: IDashboardProps) {
  const page = useForm();

  const { onLogout } = useContext(AuthContext);

  const userMenuItems: IUserMenuItem[] = [
    {
      label: "Профиль",
      page: "profile/info",
    },
    {
    label: "Выход",
    onClick: () => {
      // logout();
      onLogout();
    },
  }];

  return (
    <>
      <CssBaseline />
      <AppTopBar
        appTitle="ИС ВТД ТТ КС и ЛЧ МГ"
        drawerMenu={<DrawerMenu items={drawerMenuItems} />}
        toolbarContent={<UserMenu items={userMenuItems} />}
        title={page.title}
        thread={page.thread}
      />
      <div className={classes.formManager}>
        <FormManager className={classes.page} />
      </div>
    </>
  );
}

const styles = ({ mixins, spacing }: Theme) => ({
  page: {
    height: "99%",
  },
  formManager: {
    display: "flex",
    width: "100%",
    // height: `calc(100vh - ${+mixins.toolbar.minHeight! + spacing(1)}px)`,
    height: `calc(100vh - 32px - ${spacing(4)}px)`,
    overflow: "auto",
  },
});

export default withStyles(styles)(App);
