const getTypography = (scale = true) => {
  return scale ? {
    fontSize: 14,
    h1: {
      fontWeight: 500,
      fontSize: "35px",
      letterSpacing: "-0.24px",
      lineHeight: "40px",
    },
    h2: {
      fontWeight: 500,
      fontSize: "29px",
      letterSpacing: "-0.24px",
      lineHeight: "32px",
    },
    h3: {
      fontWeight: 500,
      fontSize: "24px",
      letterSpacing: "-0.06px",
      lineHeight: "28px",
    },
    h4: {
      fontWeight: 500,
      fontSize: "20px",
      letterSpacing: "-0.06px",
      lineHeight: "24px",
    },
    h5: {
      fontWeight: 500,
      fontSize: "16px",
      letterSpacing: "-0.05px",
      lineHeight: "20px",
    },
    h6: {
      color: "red",
      fontWeight: 500,
      fontSize: "14px",
      letterSpacing: "-0.05px",
      lineHeight: "20px",
    },
    subtitle1: {
      fontSize: "16px",
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: "14px",
      letterSpacing: "-0.05px",
      lineHeight: "21px",
    },
    caption: {
      fontWeight: 400,
      fontSize: "12px",
      letterSpacing: "0.03333em",
      lineHeight: "1.66",
    }
  }
  : {
      fontSize: 12,
      h1: {
        fontWeight: 500,
        fontSize: "28px",
        letterSpacing: "-0.24px",
        lineHeight: "36px",
      },
      h2: {
        fontWeight: 500,
        fontSize: "24px",
        letterSpacing: "-0.24px",
        lineHeight: "38px",
      },
      h3: {
        fontWeight: 500,
        fontSize: "20px",
        letterSpacing: "-0.06px",
        lineHeight: "24px",
      },
      h4: {
        fontWeight: 500,
        fontSize: "16px",
        letterSpacing: "-0.06px",
        lineHeight: "20px",
      },
      h5: {
        fontWeight: 500,
        fontSize: "14px",
        letterSpacing: "-0.05px",
        lineHeight: "18px",
      },
      h6: {
        color: "red",
        fontWeight: 500,
        fontSize: "12px",
        letterSpacing: "-0.05px",
        lineHeight: "16px",
      },
      subtitle1: {
        fontSize: "16px",
      },
      subtitle2: {
        fontWeight: 400,
        fontSize: "14px",
        letterSpacing: "-0.05px",
        lineHeight: "21px",
      },
      caption: {
        fontWeight: 400,
        fontSize: "11px",
        letterSpacing: "0.03333em",
        lineHeight: "1.66",
      }
    }
};

export default getTypography;
