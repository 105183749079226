import { List, makeStyles } from "@vtd/ui";
import React from "react";
import DrawerMenuItem from "./drawer-menu-item";

const useStyles = makeStyles(() => ({
  list: {
    width: 320,
  },
}));

interface IMenuItemType {
  page: string;
  label: string;
  permission?: {prefix: string, keys: string[]},
  icon: React.ReactElement;
  isSubItem: boolean;
}

interface IDrawerMenuProps {
  items: IMenuItemType[];
}

function DrawerMenu({ items = [] }: IDrawerMenuProps) {
  const classes = useStyles();

  return (
    <List
      component="nav"
      aria-label="main"
      className={classes.list}
    >
      {items.map((item, index) => (
        <DrawerMenuItem
          key={`drawer-menu-item-${index}`}
          index={index}
          page={item.page}
          label={item.label}
          permission={item.permission}
          icon={item.icon}
          isSubItem={item.isSubItem}
        />
      ))}
    </List>
  );
}

export default DrawerMenu;
