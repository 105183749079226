import * as React from "react";
import { createBrowserHistory } from "history";
import { IForm, IFormParams, IThreadItem } from "system";
import Form from "./form";
import { PageManagerReducer } from "./reducer";
import {useContext} from "react";
import AuthContext from "../../contexts/auth";

const history = createBrowserHistory();

export const FormContext = React.createContext<any>(null);
export const FormManagerContext = React.createContext<any>(null);

export const useForm = () => {
  const state = React.useContext(FormContext);
  return {
    ...state,
  };
};
export const useFormManager = () => React.useContext(FormManagerContext);

interface IPageManagerProviderProps {
  forms: IForm[];
  main: string;
  children?: React.ReactNode;
}

let nextPageId = 1;

const toQueryString = (page: string, params: IFormParams = {}) =>
  `/${page}?${
    Object.keys(params)
      .map((key: string) => `${key}=${params[key]}`)
      .join("&")}`;
      // .join("&")}` + history.location.hash;

function parseSearchString(s: string) {
  const search = new URLSearchParams(s);
  const params = {};
  // @ts-ignore
  for (const [key, value] of search.entries()) {
    // @ts-ignore
    params[key] = value;
  }
  return params;
}

export function FormProvider({ forms, main, children }: IPageManagerProviderProps) {
  const [state, dispatch] = React.useReducer(PageManagerReducer, {
    title: "",
    thread: [],
  });

  const {checkUserPermission} = useContext(AuthContext);
  const setTitle = (title: string) => {
    document.title = title;
    dispatch({ type: "set_title", payload: title });
  };

  const openForm = (name: string, params?: IFormParams, thread?: boolean) => {
    const targetForm = forms.find((pageItem: IForm) => pageItem.name === name);
    const type = thread ? "open_thread" : "open_form";

    // const form = !targetForm || forms.find((pageItem: IForm) => pageItem.name === "404");
    let form: any;
    let formParams = params;
    if (!targetForm) {
      // если страницы не найдена - редирект на 404
      form = forms.find((pageItem: IForm) => pageItem.name === "404");
    } else if (targetForm.permission) {
      const {prefix, keys, variant} = targetForm.permission as any;
      // проверяем права пользователя
      if (checkUserPermission(prefix, keys, variant)) {
        form = targetForm;
      } else {
        // если нет прав - редирект на 403
        form = forms.find((pageItem: IForm) => pageItem.name === "403");
        formParams = {};
      }
    } else {
      // если вручную хотим на 403
      if (targetForm.name === "403") {
        form = forms.find((pageItem: IForm) => pageItem.name === "403");
        formParams = {};
      } else {
        form = targetForm;
      }
    }

    if (!form) {
      throw new Error(`Page [${name}] not found`);
    }
    return new Promise(((resolve) => {
      if (thread) {
        history.push(toQueryString(form.name, formParams || {}));
      } else {
        history.replace(toQueryString(form.name, formParams || {}));
      }

      dispatch({ type, payload: {
        id: `page-route-${nextPageId++}`,
        name: form.name,
        component: <Form key={form.name} form={form} params={formParams || {}} />,
        params: formParams || {},
        close: (ret?: IFormParams, p?: IThreadItem) => {
          resolve(ret);
          if (p) {
            history.replace(toQueryString(p.name, p.params || {}));
          } else {
            openForm(main, {}, true)
              .then();
          }
        },
      }});
    }));
  };

  const updateParams = (name: string, params?: IFormParams) => {
    history.push(toQueryString(name, params || {}));
    dispatch({type: "update_params", payload: params});
  };

  const closeForm = (params?: IFormParams) => {
    dispatch({ type: "close_form", payload: params || {} });
  };

  const actions = React.useMemo(() => ({
    setTitle,
    openForm,
    closeForm,
    updateParams
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  React.useEffect(() => {
    const page = history.location.pathname.substr(1) || main;
    const params = parseSearchString(history.location.search);
    openForm(page, params, true)
      .then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [main]);

  return (
    <FormContext.Provider value={state}>
      <FormManagerContext.Provider value={actions}>
        {children}
      </FormManagerContext.Provider>
    </FormContext.Provider>
  );
}

export default FormProvider;
