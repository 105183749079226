import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import forms from "./forms";
import { SystemProvider } from "./system";
// import theme from "./theme";
// import "./theme/fonts/roboto-v20-latin_cyrillic/roboto-v20-latin_cyrillic.css";
// import "./theme/fonts/iconfont/material-icons.css";

const root = document.getElementById("root");

function AppContainer() {
  return (
    <SystemProvider
      // theme={theme}
      main="home"
      forms={forms}
    >
      <App />
    </SystemProvider>
  );
}

const render = (Component: any) => ReactDOM.render(<Component />, root);

render(AppContainer);
