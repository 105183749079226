import * as React from "react";

export interface IObjectState {
  [key: string]: any;
}

export function useObjectState<T extends IObjectState>(initialState?: T):
  [T|IObjectState, (newState: IObjectState) => void] {
  const [state, setStateRaw] = React.useState(initialState || {});

  const setState = (partialState: any): any => setStateRaw({
    ...state,
    ...(
      typeof partialState === "function"
        ? partialState(state)
        : partialState
    ),
  });

  return [state, setState];
}

const getHeight = () => window.innerHeight
  || document.documentElement.clientHeight
  || document.body.clientHeight;

const getWidth = () => window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;

export function useWindowHeight() {
  const [height, setHeight] = React.useState<number>(getHeight());

  React.useEffect(() => {
    let timeoutId: any = null;
    const resizeListener = () => {

      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setHeight(getHeight()), 300);
    };
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return height;
}

export function useWindowWidth() {
  const [width, setWidth] = React.useState<number>(getWidth());

  React.useEffect(() => {
    let timeoutId: any = null;
    const resizeListener = () => {

      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setWidth(getWidth()), 300);
    };
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return width;
}
