import {Box, Divider, Drawer, Link, makeStyles, Switch, Theme, Typography} from "@vtd/ui";
import React, {useContext} from "react";
import { useFormManager } from "../../../system";
import {SystemContext} from "../../../system/provider";

export interface IAppDrawerProps {
  open: boolean;
  appTitle: string;
  onClose: () => void;
  variant?: "permanent" | "persistent" | "temporary";
  children: any;
}

const drawerWidth = 340;

const styles = ({ mixins, palette }: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerHeader: {
    alignItems: "center",
    display: "flex",
    padding: "0 8px",
    ...mixins.toolbar,
  },
  drawerTitle: {
    paddingLeft: 12,
  },
  appTitle: {
    cursor: "pointer",
    color: palette.text.primary,
    fontSize: 18,
  },
});

const useStyles = makeStyles(styles);

function AppDrawer({ open, appTitle, onClose, variant = "temporary", children }: IAppDrawerProps) {
  const {appScale, setAppScale} = useContext(SystemContext);
  const { openForm } = useFormManager();
  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      variant={variant}
      open={open}
      onClose={() => onClose()}
    >
      <div
        role="presentation"
        onClick={() => onClose()}
        onKeyDown={() => onClose()}
      >
        <div className={classes.drawerHeader}>
          <Typography
            className={classes.drawerTitle}
          >
            <Link
              className={classes.appTitle}
              onClick={() => openForm("home", {}, true)}
            >
              {appTitle}
            </Link>
          </Typography>
        </div>
        <Divider />
        {children}
      </div>
      <Divider />
      <Box display="flex" alignItems="center" style={{margin: 16, marginLeft: 24}}>
        <Typography style={{fontSize: 10}}>Aa</Typography>
        <Switch
          checked={appScale}
          onChange={({target}) => setAppScale(target.checked)}
          color="default"
          size="small"
        />
        <Typography style={{fontSize: 16}}>Aa</Typography>
      </Box>
    </Drawer>
  );
}

export default AppDrawer;
