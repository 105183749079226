import React, {useEffect, useState} from "react";

import {FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput} from "@vtd/ui";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

interface IPasswordFieldProps {
  name: string
  value: string
  width?: number
  error: boolean
  onChange: (e: any) => void
  label?: string
  required?: boolean
  classNames?: string
  styles?: any
}

export const PasswordField: React.FC<IPasswordFieldProps> = ({
   name, value, width, error, onChange, label = "", required = false, classNames = "", styles = {}
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const inputLabel = React.useRef<HTMLLabelElement>(null);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

    const handleKeyDown = (e: any) => {
        if (e.ctrlKey) {
            e.preventDefault();
        }
    }

  const handleMouseDownPassword = (event: any) => event.preventDefault();

  useEffect(() => {
    if (inputLabel.current) {
        setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  return (
    <FormControl
        style={{...styles, width: width || "100%"}}
        variant="outlined"
        className={classNames}
        onContextMenu={(e) => e.preventDefault()}
        onMouseDown={(e) => {
            if (e.detail > 1) {
                e.preventDefault();
            }
        }}
    >
      <InputLabel
          ref={inputLabel}
          htmlFor="new-field"
          required={required}
          error={error}
      >
          {label}
      </InputLabel>
      <OutlinedInput
        id="new-password"
        type={showPassword ? "text" : "password"}
        value={value}
        name={name}
        labelWidth={labelWidth}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        onDoubleClick={(e) => e.preventDefault()}
        error={error}
        endAdornment={
          <InputAdornment position="end" >
            <IconButton
              tabIndex={-1}
              style={{marginRight: -6}}
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  )
}
