import React, {useCallback, useEffect, useReducer, useState} from "react";

import { LinearProgress } from "@vtd/ui";

import AuthContext from "contexts/auth";

import {getRefreshToken, IUser} from "../api/auth";
import * as AuthApi from "../api/auth";

import AuthPage from "../forms/auth";
import {vtdRoles} from "../constants/vtd-roles";
import {useSnackbar} from "notistack";

interface IAuth {
  children: React.ReactNode;
}

function authReducer(state: { user: IUser | null }, action: any) {
  switch (action.type) {
    case "CHECK":
      return { ...state, user: action.payload };
    case "LOGIN":
      return { ...state, user: action.payload };
    case "LOGOUT":
      return {...state, user: null};
    default:
      return {...state};
  }
}

export default function JwtAuth({ children }: IAuth) {
  const [state, dispatch] = useReducer(authReducer, {user: null});
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const onCheckAuth = useCallback(async () => {
    try {
      if (getRefreshToken()) {
        const user = await AuthApi.checkAuth();
        dispatch({
          type: "CHECK",
          payload: user
        });
        return user;
      }
    } catch (e) {
      // console.error("CHECK_ERROR", e);
      dispatch({
        type: "LOGOUT"
      });
      enqueueSnackbar("Пожалуйста, войдите в систему", { variant: "warning" });
      // throw e;
    } finally {
      setLoading(false);
    }
  }, [dispatch, enqueueSnackbar]);

  const onLogin = async (data: any) => {
    const {name, password} = data;
    const user = await AuthApi.signIn(name, password);
    dispatch({
      type: "LOGIN",
      payload: user
    });
    return user;
  };

  const onLogout = async () => {
    try {
      await AuthApi.signOut();
      dispatch({
        type: "LOGOUT"
      });
    } catch (e) {
      console.error("Logout-error", e);
    }
  };

  const checkIsAdmin = () => {
    const grants: string | string[] = state.user.roles.grantedAuthority;
    return grants.includes("ROLE_ADMIN");
  }

  // проверка есть ли у пользователя разрешения на keys
  const checkUserPermission = (prefix: string, keys: string[], variant = "every"): boolean => {

    // проверка на суперадмина
    if (checkIsAdmin()) {
      return true;
    }

    const roleGroup = vtdRoles.find((group) => group.key === prefix);
    if (roleGroup) {
      const userGroupPermissions = state.user.roles[prefix] || 0;
      const values = roleGroup.items
          .filter((role) => keys.includes(role.key))
          .map((item) => item.value)
          // tslint:disable-next-line:no-bitwise
          .map((item) => Boolean(userGroupPermissions & item));

      // если есть разрешения на keys, возвращаем true
      return variant === "every" ? values.every((v) => v) : values.some((v) => v);
    } else {
      return false;
    }
  }

  useEffect(() => {
    onCheckAuth();
  }, [onCheckAuth]);

  return (
    <>
      {
        loading ?
          <LinearProgress color="secondary" variant="query"/> :
          <AuthContext.Provider value={{
            user: state.user,
            onCheckAuth,
            onLogin,
            onLogout,
            checkUserPermission
          }}>
            {(!!state.user) ? children : <AuthPage/>}
          </AuthContext.Provider>
      }
    </>);
}
