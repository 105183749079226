import { IThreadItem } from "..";

export interface IPageManagerState {
  title: string;
  thread: IThreadItem[];
}

export type PageManagerAction =
  | { type: "set_title", payload: string }
  | { type: "open_thread", payload: IThreadItem }
  | { type: "open_form", payload: IThreadItem }
  | { type: "update_params", payload: any }
  | { type: "close_form", payload: any };

export const PageManagerReducer = (state: IPageManagerState, { type, payload }: PageManagerAction) => {
  switch (type) {
    case "set_title": {
      const newThread = [...state.thread];
      newThread.push(({ ...newThread.pop(), title: payload } as IThreadItem));
      return {
        ...state,
        title: payload,
        thread: newThread,
      };
    }

    case "open_thread": {
      const newThread = [payload];
      return {
        ...state,
        thread: newThread,
      };
    }

    case "open_form": {
      const newThread = [...state.thread, payload];
      return {
        ...state,
        thread: newThread,
      };
    }

    case "update_params": {
      const newThread = [...state.thread];
      const lastThread = newThread.pop() as IThreadItem;
      return {
        ...state,
        thread: [...newThread, {...lastThread, params: payload}],
      };
    }

    case "close_form": {
      const newThread = [...state.thread];
      const closedPage = newThread.pop() as IThreadItem;
      const selectedPage = newThread[newThread.length - 1];
      closedPage.close(payload || {}, selectedPage);
      document.title = selectedPage?.title ?? "";
      return {
        ...state,
        title: selectedPage ? selectedPage.title : "",
        thread: newThread,
      };
    }

    default:
      throw new Error("Unexpected action");
  }
};
