import axios from "axios";

const instance = axios.create({
  baseURL: "/api",
  timeout: 60000
});

let HttpClient = {
  instance,
  async get(...args: any) {
    try {
      const res = await instance.get.apply(this, args);
      // @ts-ignore
      return res.data;
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error("[API ERROR]", e);
      throw e;
    }
  },
  async post(...args: any) {
    try {
      const res = await instance.post.apply(this, args);
      // @ts-ignore
      return res.data;
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error("[API ERROR]", e);
      throw e;
    }
  },
  async put(...args: any) {
    try {
      const res = await instance.put.apply(this, args);
      // @ts-ignore
      return res.data;
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error("[API ERROR]", e);
      throw e;
    }
  },
  async patch(...args: any) {
    try {
      const res = await instance.patch.apply(this, args);
      // @ts-ignore
      return res.data;
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error("[API ERROR]", e);
      throw e;
    }
  },
  async delete(...args: any) {
    try {
      const res = await instance.delete.apply(this, args);
      // @ts-ignore
      return res.data;
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error("[API ERROR]", e);
      throw e;
    }
  },
};

export function registerHttpClient(provider: any) {
  HttpClient = provider;
}

const setToken = (token: string) => {
  HttpClient.instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const api = {
  instance,
  setToken,
  async get(...args: any) {
    return HttpClient.get(...args);
  },
  async post(...args: any) {
    return HttpClient.post(...args);
  },
  async put(...args: any) {
    return HttpClient.put(...args);
  },
  async patch(...args: any) {
    return HttpClient.patch(...args);
  },
  async delete(...args: any) {
    return HttpClient.delete(...args);
  },
};

export default api;
