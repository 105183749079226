import { LinearProgress, withStyles } from "@vtd/ui";
import clsx from "clsx";
import * as React from "react";
import { IThreadItem } from "../index";
import { useForm } from "./form-provider";

interface IPageManagerProps {
  className?: string;
  classes?: any;
}

function FormManager({ className, classes }: IPageManagerProps) {
  const page = useForm();
  const isActive = (threadItem: IThreadItem) =>
    (((page.thread[page.thread.length - 1]! as IThreadItem).id === threadItem.id));

  return (
    <React.Suspense
      fallback={<LinearProgress color="secondary" variant="query" />}
    >
      {page.thread.map((threadItem: IThreadItem) =>
        <section
          key={threadItem.id}
          id={threadItem.id}
          hidden={!isActive(threadItem)}
          // style={{display: `${isActive(threadItem) ? "block" : "none"}`}}
          className={clsx(classes.container, className)}
        >
          {isActive(threadItem) && threadItem.component}
        </section>
      )}
    </React.Suspense>
  );
}

const styles = () => ({
  container: {
    width: "inherit",
    height: "inherit",
  },
});

export default withStyles(styles)(FormManager);
