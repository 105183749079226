import React, {useContext, useState} from "react";

import {Divider, IconButton, Menu, MenuItem, Typography} from "@vtd/ui";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { useFormManager } from "system";
import { IUserMenuItem } from "..";

import AuthContext from "contexts/auth";

export interface IUserMenuProps {
  items: IUserMenuItem[];
}

function UserMenu({ items }: IUserMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>();
  const { openForm } = useFormManager();

  const {user} = useContext(AuthContext);

  return (
    <div>
      <IconButton
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <AccountCircleIcon />
      </IconButton>
      {/*  <Button*/}
      {/*      color="inherit"*/}
      {/*      suffix={<AccountCircleIcon />}*/}
      {/*      onClick={(e) => setAnchorEl(e.currentTarget)}*/}
      {/*  >*/}
      {/*      <span style={{textTransform: "none"}}>{user?.shortName || ""}</span>*/}
      {/*  </Button>*/}
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        // keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!anchorEl}
        onClose={() => setAnchorEl(undefined)}
      >
          <div style={{padding: "0 24px"}}>
              <Typography>{user?.shortName || ""}</Typography>
          </div>
          <Divider style={{margin: "8px 0"}}/>
        {items.map((item, index) => (
          <MenuItem
            key={`menu-item-${index}`}
            onClick={() => {
              setAnchorEl(undefined);
              if (item.onClick instanceof Function) {
                item.onClick.call(null);
              }
              if (item.page) {
                openForm(item.page, {}, true);
              }
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default UserMenu;
